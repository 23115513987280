import { reducer as form } from 'redux-form';
import toastsReducer from '@zola-helpers/client/dist/es/redux/toasts/toastsReducer';
import modal from './modal';
import user from './user';
import nav from './nav';
import notifications from './notifications';
import registryCollection from './registryCollection';
import moduleGroup from './moduleGroup';
import product from './product';
import shop from './shop';
import personalization from './personalization';

export default {
  form,
  modal,
  user,
  nav,
  toasts: toastsReducer,
  notifications,
  product,
  registryCollection,
  moduleGroup,
  shop,
  personalization,
};
